<template>
  <div class="containerIndex">
    <div class="topImg" ref="topImg">
      <a href="/" target="_blank" v-if="viewImgTop">
        <!-- <img src="../assets/img/beijin.jpg" alt="" style="width: 100%" /> -->
        <img :src="content.fileName" :alt="content.title" style="width: 100%" />
      </a>
    </div>
    <!-- header  大于1300px时的 -->
    <div class="navBox trans" :class="{ active: activeNav == 1 }">
      <div class="navIco" >
        <div class="navIcoInner navIcoSearch">
          <span
            v-if="viewSearch"
            class="navIcoSearchBtn"
            @click="searchBtn(false)">
            <img src="../assets/img/ico_search.png" alt=""/>
          </span>
          <span
            v-if="!viewSearch"
            class="navIcoSearchBtn"
            @click="searchBtn(true)">
            <img src="../assets/img/ico_search.png" alt=""/>
          </span>
          <div class="navSearchInput" v-if="viewSearch">
            <el-input placeholder="请输入关键词" v-model="searchValue" size="small">
              <el-button slot="prepend" icon="el-icon-search" @click="searchSubmit"></el-button>
            </el-input>
          </div>
        </div>
        <div class="navIcoInner navIcoLine"></div>
        <a class="navIcoInner navIcoMail" href="/listPage/0127">
          <img src="../assets/img/ico_mail.png" alt=""/>
        </a>
      </div>
      <div class="navWrap wrap clearfloat">
        <div class="topwrap">
          <div class="fl indexLogo">
            <a href="/" class="logoImgPc"><img :src="logo" alt="" /></a>
          </div>
        </div>
        <div class="fr nav_box_fr">
          <div class="nav_box">
            <ul class="nav_box_list clearfloat">
              <li style="border-left: none">
                <div class="nav_grop">
                  <h2><router-link to="/">网站首页</router-link></h2>
                </div>
              </li>
              <li
                v-for="(item, index) in navList"
                :key="index"
                :class="{ borderR: index == navList.length - 1 }"
                @mouseenter="navShow(item.channelId)"
                @mouseleave="navClose()">
                <div class="nav_grop">
                  <!-- <h2 v-if="item.entTChannelUuid == '0118'">
                    <a :href="item.child[0].child[0].catalog == 1 ? `/listPage/${item.child[0].child[0].entTChannelUuid}` 
                       : item.child[0].child[0].catalog == 2 ? `/list/${item.child[0].child[0].entTChannelUuid}` 
                       : `/list/${item.child[0].child[0].entTChannelUuid}`">
                      {{ item.name }}
                    </a>
                  </h2> -->
                  <h2>
                    <a v-if="item.child.length && item.child[0].url && item.child[0].url !== ''"
                      target="_blank"
                      :href="item.child[0].url">
                      {{ item.name }}
                    </a>
                    <a v-if="item.child.length && (!item.child[0].url || item.child[0].url == '')"
                      :href="item.catalog == 1 ? `/listPage/${item.child[0].entTChannelUuid}`
                      : item.catalog == 2 ? `/list/${item.child[0].entTChannelUuid}`
                      : `/list/${item.child[0].entTChannelUuid}`">
                      {{ item.name }}
                    </a>
                    <a v-if="!item.child.length"
                      :href=" item.catalog == 1? `/listPage/${item.entTChannelUuid}`
                      : item.catalog == 2 ? `/list/${item.entTChannelUuid}`
                      : `/list/${item.entTChannelUuid}`">
                      {{ item.name }}
                    </a>
                  </h2>

                  <el-collapse-transition>
                    <div class="nav_down" v-show="navDown == item.channelId">
                      <div class="wrap nav_down_wrap clearfloat" v-show="item.child.length != 0">
                        <p v-for="(item1, index1) in item.child" :key="index1">
                          
                          <a v-if="!item1.child.length && (!item1.url || item1.url == '')"
                            :href="item1.catalog == 1 ? `/listPage/${item1.entTChannelUuid}`
                            : item1.catalog == 2 ? `/list/${item1.entTChannelUuid}`
                            : `/list/${item1.entTChannelUuid}`"
                            target="_blank">
                            {{ item1.name }}
                          </a>

                          <a v-if="!item1.child.length && (item1.url && item1.url !== '') "
                            :href="item1.url"
                            target="_blank">
                            {{ item1.name }}
                          </a>

                          <a v-if="item1.child.length && item1.child[0].url && item1.child[0].url !== ''"
                            :href="item1.url"
                             target="_blank">
                            {{ item1.name }}
                          </a>

                          <a v-if="item1.child.length && (!item1.child[0].url || item1.child[0].url == ''|| item1.child[0].url == null ) && item1.entTChannelUuid !='011500050003'"
                            :href="item1.catalog === 1? `/listPage/${item1.child[0].entTChannelUuid}`
                            : item1.catalog === 2? `/list/${item1.child[0].entTChannelUuid}`
                            : `/list/${item1.child[0].entTChannelUuid}`" 
                            target="_blank">
                            {{ item1.name }}
                          </a>

                          <a v-if="item1.child.length && (!item1.child[0].url || item1.child[0].url == '') && item1.entTChannelUuid =='011500050003'"
                            :href="`/list/011500050003`"
                             target="_blank">
                            {{ item1.name }}
                          </a>
                        </p>
                      </div>
                    </div>
                  </el-collapse-transition>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- header  小于1300px时的 -->
    <div id="topPart">
      <div class="top">
        <div class="topLogo">
          <div class="topLogoMain">
            <div class="topLogoMainLeft">
              <!-- <a href="/" class="logoImgPc"><img src="../../assets/image/Nlogo.png" class="Nlogo"/></a>
              <a href="/" class="logoImgMobile"><img src="../../assets/image/Nlogo_mobile.png" class="Nlogo"/></a> -->
              <a href="/" class="logoImgMobile"><img :src="logo" alt="" /></a>
            </div>
            <div class="topLogoMainRight">
              <div class="header_search">
                <div>
                  <el-input v-model="searchValue" placeholder="请输入标题" style="width: 300px;"></el-input>
                  <el-button
                    type="submit"
                    class="btn "
                    style="background-color:#0d65af; border-radius:0px;"
                     @click="searchSubmit">搜索
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="topMenu">
          <div class="topMenuMain">
            <el-menu
              
              class="el-menu-demo"
              background-color="rgba(0,0,0,0)"
              text-color="#ffffff"
              active-text-color="rgb(108, 171, 231)"
              mode="horizontal"
              :router="false"
            > 
              <div style="display:inline-block">
                <li class="el-menu-item2">
                  <a style="color: #fff"
                    href="/">
                    网站首页
                  </a>
                </li>
                 <span class="span1" ></span>
              </div>
              <div
                style="display:inline-block"
                :class="{ active: active  == item.entTChannelUuid}"
                v-for="(item, index) in navList"
                :key="index + '001'"
              >
                <el-menu-item :index="item.index">
                  <a v-if="item.child.length && item.child[0].url && item.child[0].url !== ''"  style="color: #fff"
                    target="_blank"
                    :href="item.child[0].url">
                    {{ item.name }}
                  </a>
                  <a v-if="item.child.length && (!item.child[0].url || item.child[0].url == '')"  style="color: #fff"
                    :href="item.catalog == 1 ? `/listPage/${item.child[0].entTChannelUuid}`
                    : item.catalog == 2 ? `/list/${item.child[0].entTChannelUuid}`
                    : `/list/${item.child[0].entTChannelUuid}`">
                    {{ item.name }}
                  </a>
                  <a v-if="!item.child.length" style="color: #fff"
                    :href=" item.catalog == 1? `/listPage/${item.entTChannelUuid}`
                    : item.catalog == 2 ? `/list/${item.entTChannelUuid}`
                    : `/list/${item.entTChannelUuid}`">
                    {{ item.name }}
                  </a>
                </el-menu-item>
                <span class="span1" v-if="index < navList.length - 1"></span>
                <span class="span2"></span>
              </div>
            </el-menu>
          </div>
        </div>
      </div>
    </div>



    <!-- main -->
    <div class="mainBox containerMain" :style="containerStyle">
      <router-view></router-view>
    </div>

    <!-- footer -->
    <div class="footerBox">
      <div class="wrap">
        <el-row :gutter="10" style="margin: 0;">
          <!-- <el-col :span="12"> -->
          <el-col :lg="12" :xs="24" :xl="12" :sm="12">
            <div class="footerLeft">
              <p>
                 版权所有：甘肃第六建设集团股份有限公司 
              </p>
              <p>
                <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color: #fff;"> 《中华人民共和国电信与信息服务业务经营许可证》编号： 陇ICP备15001306号-1</a>
              </p>
              <p>
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62010202000794" target="_blank" style="color: #fff;">甘公网安备 62010202000794号  </a>
              </p>
            </div>
          </el-col>
          <!-- <el-col :span="9" :offset="3"> -->
          <el-col :xl="12" :xs="24" :lg="12" :sm="12">
            <div class="footerRight">
              <div class="footerRightBox">
                <h3>联系我们</h3>
                <ul class="footerRightList">
                  <li>
                    <span class="footerIco">
                      <!-- <img src="../assets/img/ico_address.png" alt=""> -->
                      <!-- <el-image
                        :src="require('../assets/img/ico_address.png')"
                        fit="contain"
                      ></el-image> -->
                    </span>
                    兰州城关区飞雁街124号(陇星园区B座5、6号楼)
                  </li>
                  <li>
                    <span class="footerIco">
                      <!-- <img src="../assets/img/ico_tel.png" alt=""> -->
                      <!-- <el-image
                        :src="require('../assets/img/ico_tel.png')"
                        fit="contain"
                      ></el-image> -->
                    </span>
                    （0931）8341225
                  </li>
                  <li>
                    <span class="footerIco">
                      <!-- <img src="../assets/img/ico_mial.png" alt=""> -->
                      <!-- <el-image
                        :src="require('../assets/img/ico_mial.png')"
                        fit="contain"
                      ></el-image> -->
                    </span>
                    admin@gsljw.com(系统邮箱，请勿投递简历)
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getNavChannel,getAdvert } from '@/api/common'
export default {
  name: "",
  data() {
    return {
      activeNav: 0,
      navSubList: [],
      containerStyle: {},
      viewImgTop: true,
      content: "",
      viewSearch: false,
      searchValue: "",
      // logo: require('@/assets/img/logo.jpg'),
      // logo: require('@/assets/img/logo_hei.jpg'),
      // logo: require('@/assets/img/logo_sun.jpg'),
      logo: require("@/assets/img/logo_lj.png"),
      logo2: require("@/assets/img/logo2.png"),
      active: 0 ,
      navList: [],
      navDown: 0,
    };
  },
  mounted() {
    this.getPath();
    this.getNavChannel();
    this.active = this.$route.params.id
    let url = this.$route.name
    if (url == 'home') {
      this.srollimg()
    }
    this.srollChannelTop()
  },
  created() {},
  watch: {
    $route: "getPath",
  },
  methods: {
    searchSubmit() {
      var _this = this
      if (!_this.searchValue || _this.searchValue == '') {
        this.$message({
          message: '请输入关键词进行搜索！',
          type: 'warning',
          offset: 200
        })
      } else {
        window.location.href = `/search/${_this.searchValue}`
        // window.open(`/search/${_this.searchValue}`)
      }
    },
    srollimg() {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        advertType: 4
      }
      getAdvert(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          data.length && (_this.content = data[0])
          this.$nextTick(() => {
            this.srollTop()
          })
        }
      })
    },
    srollTop() {
      var _this = this;
      var heightT;
      setTimeout(() => {
        heightT = this.$refs.topImg.offsetHeight;
        _this.containerStyle = {
          marginTop: heightT + "px",
        };
      }, 500);
      setTimeout(() => {
        _this.containerStyle = {
          marginTop: 0,
        };
      }, 3000);
      setTimeout(() => {
        _this.viewImgTop = false;
      }, 6000);
    },

    srollChannelTop() {
      var _this = this;
      let url = this.$route.name
      if (url == 'home') {
        setTimeout(() => {
          _this.activeNav = 1;
        }, 4000);
      }
      else{
        _this.activeNav = 1;
      }
    },

    searchBtn(val) {
      var _this = this;

      _this.viewSearch = val;
    },
    // 获取导航
    getNavChannel() {
      var _this = this
      let data = {
        applicationUuid: _this.webId
      }
      getNavChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.navList = data.slice(0, 7)
          // console.log(123456,_this.navList)
        }
      })
    },

    // 二级导航展开
    navShow(d) {
      let _this = this;
      // _this[d] = true
      _this.navDown = d;
    },
    // 二级导航收起
    navClose() {
      let _this = this;
      // _this[d] = false
      _this.navDown = 0;
    },
    haddleNav(index, params) {
      var _this = this;

      _this.active = index;
      _this.$router.push(params.url);
    },
    getPath() {
      var _this = this;
      _this.active = _this.$route.meta.index;
    },
  },
};
</script>
<style>
#topPart{
  display: none;
}
.indexLogo {
  padding-top: 6px;
}
.indexLogo img {
  width: auto;
  height: 60px;
}
.navBox {
  height: 72px;
  min-width: 1280px;
  background-color: #fff;
  position: fixed;
  top: -80px;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  z-index: 9999;
}
.navBox.active {
  top: 0;
}
.nav_box {
  padding-top: 15px;
}
/* .topLogoMainRight {
  width: 380px;
  height: 50px;
  margin-top: 45px;
  overflow: hidden;
  display: none;
}
.header_search {
  width: 407px;
  height: 48px;
  overflow: hidden;
  position: relative;
}
.header_search input {
  width: 300px;
  height: 38px;
  overflow: hidden;
  outline: none;
  box-shadow: none;
  line-height: 38px;
  font-size: 14px;
  color: #585858;
  border-radius: 0px;
  border: solid 1px #0d65af;
}
.btn {
  display: inline-block;
  height: 38px;
  line-height: 39px;
  padding: 0px 12px;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border: none;
  cursor: pointer;
} */
.nav_box_list li {
  float: left;
  /* height: 50px; */
  line-height: 50px;
  margin: 0 13px;
}
.nav_box_list li h2 a {
  display: block;
  color: #0062b1;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 8px;
  position: relative;
  /* padding: 0 4px; */
}
.nav_box_list li h2 a::before {
  display: block;
  content: "";
  position: absolute;
  width: 0;
  height: 3px;
  background-color: #0062b1;
  left: 50%;
  bottom: 15px;
  margin-left: 0;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.nav_box_list li:hover h2 a::before {
  width: 50px;
  margin-left: -25px;
}
.nav_down {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 98, 177, 0.4);
  color: #fff;
  left: 0;
  top: 72px;
  text-align: center;
}
.nav_down_wrap {
  padding: 8px 0;
}
.nav_down a {
  color: #fff;
}
.nav_down a:hover {
  font-weight: bold;
}
.nav_down p {
  display: inline-block;
  font-size: 16px;
  margin: 0 20px;
}
.navIco {
  position: absolute;
  right: 20px;
  bottom: 19px;
}
.navIcoInner {
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  vertical-align: top;
}
.navIcoInner img {
  width: 100%;
  vertical-align: middle;
}
.navSearchInput .el-input-group__prepend {
  padding: 0 8px;
}
.navIcoMail img {
  width: 26px;
  height: auto;
}
.navIcoLine {
  width: 2px;
  margin: 0 6px 0 4px;
  background-color: #0062b1;
}
.navIcoSearchBtn {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
}
.navIcoSearch {
  position: relative;
}
.navSearchInput {
  position: absolute;
  width: 210px;
  right: 30px;
  top: -2px;
  z-index: 22;
}
.footerBox {
  padding: 20px 0 30px;
  background-color: #0062b1;
  color: #fff;
  font-size: 16px;
}
.footerLeft {
  text-align: center;
  padding-top: 18px;
  line-height: 18px;
  margin-right: 10px;
}
.footerLeft span {
  display: inline-block;
}
.footerLeft span:first-child {
  margin-right: 20px;
}
.footerLeft p {
  margin: 22px 0;
  font-size: 14px;
}
.footerLeft {
  text-align: center;
}
.footerRightBox {
  text-align: center;
}
.footerRightBox h3 {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.2em;
  margin-bottom: 25px;
}

.footerRightList li {
  line-height: 18px;
  margin: 16px 0;
  font-size: 14px;
}
.footerIco {
  display: inline-block;
  width: 18px;
  height: 18px;
  vertical-align: top;
  margin-right: 12px;
}
.topImg {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -10;
  overflow: hidden;
  -moz-transition: all 1s ease-in;
  -webkit-transition: all 1s ease-in;
  -o-transition: all 1s ease-in;
  transition: all 1s ease-in;
}

.topImg img {
  display: block;
  width: 100%;
}
.containerMain {
  -moz-transition: all 1s ease-in;
  -webkit-transition: all 1s ease-in;
  -o-transition: all 1s ease-in;
  transition: all 1s ease-in;
  z-index: 10;
  background-color: #fff;
}


.topHeader {
  width: 100%;
  height: auto;
  background-color: #f1f6fa;
  overflow: hidden;
  color: #0162b3;
  font-size: 14px;
}
.topHeaderMain {
  width: 1200px;
  margin: 0 auto;
  height: 31px;
  line-height: 31px;
}
.topHeaderMainLeft {
  display: block;
  float: left;
  width: 340px;
  overflow: hidden;
}
.topHeaderMainRight {
  display: block;
  float: right;
  overflow: hidden;
  color: #a2a2a2;
}
.topLogo {
  overflow: hidden;
  padding: 10px 0px;
}
.topLogoMain {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.topLogoMainLeft {
  width: 710px;
  overflow: hidden;
  margin-top: 4px;
  margin-bottom: 4px;
}
.topLogoMainLeft {
  float: left !important;
}
.topLogoMainRight {
  width: 380px;
  height: 50px;
  margin-top: 45px;
  overflow: hidden;
}
.topLogoMainRight {
  float: right !important;
}
.header_search {
  width: 380px;
  height: 51px;
  overflow: hidden;
  float: right;
  position: relative;
}
.header_search input {
  width: 300px;
  height: 38px;
  overflow: hidden;
  outline: none;
  box-shadow: none;
  line-height: 38px;
  font-size: 14px;
  color: #585858;
  border-radius: 0px;
  border: solid 1px #0d65af;
}

.form-control {
  display: block;
  padding: 6px 12px;
}
.btn {
  display: inline-block;
  height: 38px;
  line-height: 38px;
  padding: 0px 12px;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border: none;
  cursor: pointer;
  vertical-align: top;
  width: 52px;
}
.topMenu {
  height: 50px;
  background-color: #0162b3;
  overflow: hidden;
  border-bottom: 1px #ffffff solid;
}
.topMenuMain {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
}
.el-menu-item {
  width: 148px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  float: left;
  color: #ffffff;
  display: block;
  font-size: 16px;
  font-weight: 600;
  /* margin-top: -10px; */
}
.el-menu-item2 {
  width: 148px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  float: left;
  color: #ffffff;
  display: block;
  font-size: 16px;
  font-weight: 600;
}

.el-menu-item:hover {
  background-color: rgba(7, 6, 63, 0.425) !important;
}
.el-menu--horizontal > .el-menu-item {
  float: left;
  height: 60px;
  line-height: 52px;
  margin: 0;
  border-bottom: 2px solid transparent;
}
.el-menu .span1 {
  width: 2px;
  height: 50px;
  background: url(../assets/image/menu_line.jpg) center center no-repeat;
  display: inline-block;
}
.el-menu .span2 {
  width: 2px;
  height: 50px;
  display: inline-block;
}
.topMenuMain.active .el-menu-item {
  color: #fff;
  background-color: rgba(7, 6, 63, 0.425) !important;
}


@media (max-width: 1300px) {
  #topPart{
  display: block;
  background-color: #fff;
}
.navBox{
  display: none;
}
  .containerIndex{
    width: auto;
    min-width: auto;
  }
    .navBox{
    min-width: auto;
    /* height: 170px; */
    height: auto;
    background-color: #fff;
    position: fixed;
    top: -200px;
    left: 0;
    width: 100%;
    z-index: 9999;
  }
  .navIco {
    position: absolute;
    right: 20px;
    top: 19px;
}
.nav_box_fr{
    height: auto;
    float: none;
    text-align: center;
    height: 50px;
    width: 100%;
    overflow: hidden;
  }
  .indexLogo {
    margin-left: 20px;
  }
  .nav_box {
     padding-top: 0px; 
}
.nav_box_list {
  text-align: center;
    width: auto;
    margin: 0 auto;
}
.el-menu-item {
    width: 120px;
  }
  .el-menu-item2 {
    width: 120px;
}
  .topHeaderMain,
  .topLogoMain {
    width: 1000px;
  }
  .topLogoMainLeft {
    width: 500px;
  }
  .topLogoMainLeft img {
    width: 100%;
  }
  .topLogoMainRight {
    margin-top: 26px;
  }
  .wrap {
    width: auto;
}

}
@media (max-width: 992px) {
   #topPart{
  display: block;
  background-color: #fff;
}
.topImg{
  display: none;
}
.wrap {
    width: auto;
}
.navBox{
  display: none;
}
  .containerIndex{
    width: auto;
    min-width: auto;
  }
  .navBox{
    min-width: auto;
    /* height: 170px; */
    height: auto;
    background-color: #fff;
    position: fixed;
    top: -200px;
    left: 0;
    width: 100%;
    z-index: 9999;
  }
  .navIco {
    position: absolute;
    right: 20px;
    top: 19px;
}
.nav_box_fr{
    height: auto;
    float: none;
    text-align: center;
    height: 50px;
    width: 100%;
    overflow: hidden;
  }
  .indexLogo {
    margin-left: 20px;
  }
  .nav_box {
     padding-top: 0px; 
}
.nav_box_list {
  text-align: center;
    width: auto;
    margin: 0 auto;
}
.topHeaderMain,
  .topLogoMain {
    width: 94%;
  }
  .topLogoMainLeft {
    width: 50%;
  }
  .topLogoMainRight {
    width: 46%;
  }
  .header_search {
    width: 100%;
  }
  .header_search .el-input {
    width: 80% !important;
  }
  .header_search input {
    width: 100%;
  }
  .el-menu-item {
    width: 100px;
    padding-left: 0;
    padding-right: 0;
  }
  .el-menu-item2 {
    width: 100px;
    padding-left: 0;
    padding-right: 0;
  }
  .el-menu .span1,
  .el-menu .span2 {
    display: none;
  }

}
@media (max-width: 850px) {
  .el-menu-item {
    width: 88px;
  }
  .el-menu-item2 {
    width: 88px;
  }
}
@media (max-width: 768px) {
   #topPart{
  display: block;
  background-color: #fff;
}
.topImg{
  display: none;
}
.navBox{
  display: none;
}
  .containerIndex{
    width: auto;
    min-width: auto;
  }
  .navBox{
    min-width: auto;
    /* height: 170px; */
    height: auto;
    background-color: #fff;
    position: fixed;
    top: -200px;
    left: 0;
    width: 100%;
    z-index: 9999;
  }
  .navBox.active {
    top: 0;
  }
  .wrap{
    margin: 0 auto;
    width: auto;
  }
  .topwrap{
    margin: 0 auto;
    width: auto;
  }
  .indexLogo {
    width: 100%;
    padding-top: 6px;
    text-align: center;
  }
  .indexLogo img{
    height: auto;
    width: 90%;
    margin: 0 auto;
  }
  .indexLogo {
    margin-left: 0px;
}
  .navIco {
    display: none;
  }
  .header_search {
    width: 100%;
  }
  .header_search .el-input {
    width: 80% !important;
    height: 38px;
    line-height: 38px;
}
  .header_search input {
    width: 100%;
  }
  .nav_down{
    display: none;
  }
  .nav_box_fr{
    height: auto;
    float: none;
    text-align: center;
    height: 50px;
    width: 100%;
    overflow: hidden;
  }
  .nav_box_list li {
    float: left;
    line-height: 50px;
    margin: 0 13px;
  }
  .nav_box {
    padding-top: 0px;
    width: 100%;
    height: 50px;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .nav_box_list {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    border-bottom: none;
    padding: 0;
    height: 50px;
  }
  .footerBox{
    width: auto;
    margin: 0 auto;
    padding: 10px 0 10px;
  }
  .footerLeft {
    text-align: center;
    padding-top: 8px;
    }

  .footerLeft p a{
    line-height: 26px;
  }
  .footerLeft p {
    margin: 4px 0;
    font-size: 14px;
}
.footerRightBox h3{
  margin-bottom: 0px;
}
.footerRightList li {
    line-height: 18px;
    margin: 6px 0;
    font-size: 14px;
}
.containerMain{
  margin-top: auto;
}
.topLogoMainLeft {
    width: 100%;
    text-align: center;
  }
  .topLogoMainLeft img {
    width: 90%;
  }
  .topLogoMainRight {
    width: 100%;
    margin-top: 0;
  }
  .header_search .el-input {
    width: calc(100% - 54px) !important;
  }
  .topLogo {
    padding: 0;
  }
  .el-menu.el-menu--horizontal {
    width: auto;
    overflow-x: auto;
    white-space: nowrap;
    border-bottom: none;
    padding: 0;
    height: 50px;
  }
  .topMenuMain .el-menu-item {
    height: 50px !important;
    margin: 0 1px;
    line-height: 50px;
    border-radius: 4px;
    /* padding-bottom: 8px; */
  }
  .topMenuMain .el-menu-item2 {
    height: 50px !important;
    margin: 0 1px;
    border-radius: 4px;
    padding-bottom: 0px;
    line-height: 50px;
  }
  .el-menu .span1,
  .el-menu .span2 {
    display: none;
  }
  .topMenu {
    height: auto;
    padding-top: 0px;
  }
  .logoImgPc {
    display: none;
  }
  .logoImgMobile {
    display: block;
  }

}

</style>